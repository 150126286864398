import {
    AfterViewInit,
    Component,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    TemplateRef,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
} from '@angular/forms';
import { NzSafeAny, NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { Globals } from 'src/app/core/services/globals';

export interface SelectOption<T = unknown> {
    value: T;
    label: string;
    icon?: string;
    subLabel?: string;
    badges?: { color: string; text: string }[];
}

export interface SelectGroupOptions<T = unknown> {
    name: string;
    options: SelectOption<T>[];
}

@Component({
    selector: 'app-form-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
    @Input() formControlName: string;
    @Input() formControl: UntypedFormControl;
    @Input() options: SelectOption[] = [];
    @Input() groupOptions: SelectGroupOptions[];
    @Input() info?: string | TemplateRef<void>;
    @Input() tooltip?: string | TemplateRef<void>;
    @Input() tooltipPlacement = 'top';
    @Input() errorTip: string | TemplateRef<{ $implicit: FormControl }> = 'This field is required';
    @Input() showSearch = false;
    @Input() placeholder = '';
    @Input() isLoading = false;
    @Input() tooltipColor: string;
    @Input() compareFn: (a: NzSafeAny, b: NzSafeAny) => boolean = (a, b) => a === b;
    @Input() size: NzSizeLDSType = 'large';
    @Input() mode: NzSelectModeType = 'default';
    @Input() disabled = false;
    @Input() allowClear = true;

    value: unknown;

    propagateChange: (_: unknown) => void;
    propagateTouch: () => void;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        public globals: Globals,
    ) {}

    ngAfterViewInit(): void {
        if (this.controlContainer && !this.formControl) {
            setTimeout(() => {
                this.formControl = this.controlContainer.control.get(
                    this.formControlName,
                ) as UntypedFormControl;
            });
        }
    }

    setValue(value: unknown): void {
        this.value = value;
        this.propagateChange(this.value);
    }

    registerOnChange(fn: (_: unknown) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    writeValue(value: unknown): void {
        this.value = value;
    }
}
