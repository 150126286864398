<ul nz-menu nzMode="inline" class="sider-menu">
    <li nz-menu-item [nzMatchRouter]="true" routerLink="/home">
        <span nz-icon nzType="home"></span>
        <span>Home</span>
    </li>
    <li
        *hasPermission="['USERS__VIEW', 'USERS__VIEW_ROLES']"
        nz-submenu
        nzIcon="team"
        nzTitle="Users"
        [nzOpen]="this.openMap.users"
    >
        <ul>
            <li
                *hasPermission="'USERS__VIEW'"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/users/users"
            >
                List
            </li>
            <li
                *hasPermission="'USERS__VIEW_ROLES'"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/users/roles"
            >
                Roles
            </li>
        </ul>
    </li>
    <li
        *hasPermission="[
            'ACCUMULATORS__VIEW_ALL',
            'ACCUMULATORS__VIEW_OWN',
            'ACCUMULATORS__ADD_EDIT',
            'ACCUMULATORS__VIEW_STATISTICS'
        ]"
        nz-submenu
        nzIcon="group"
        nzTitle="Accumulators"
        [nzOpen]="this.openMap.accumulators"
    >
        <ul>
            <li
                *hasPermission="['ACCUMULATORS__VIEW_ALL', 'ACCUMULATORS__VIEW_OWN']"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/accumulators"
            >
                Accumulators list
            </li>
            <li
                *hasPermission="'ACCUMULATORS__ADD_EDIT'"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/accumulators/add"
            >
                New accumulator
            </li>
            <li
                *hasPermission="'ACCUMULATORS__VIEW_STATISTICS'"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/accumulators/statistics"
            >
                Statistics
            </li>
        </ul>
    </li>
    <li
        *hasPermission="['GAMES__VIEW_ALL', 'GAMES__VIEW_OWN']"
        nz-menu-item
        [nzMatchRouter]="true"
        routerLink="/games"
    >
        <span nz-icon nzType="global"></span>
        <span>Games</span>
    </li>
    <li
        *hasPermission="[
            'BOOKMAKERS__VIEW',
            'BOOKMAKERS__VIEW_HOLDINGS',
            'BOOKMAKERS__VIEW_PAYMENT_METHODS',
            'BOOKMAKERS__VIEW_BONUSES'
        ]"
        nz-submenu
        nzIcon="dollar-circle"
        nzTitle="Bookmakers"
        [nzOpen]="this.openMap.bookmakers"
    >
        <ul>
            <li *hasPermission="'BOOKMAKERS__VIEW'" nz-menu-item [nzMatchRouter]="true">
                <a routerLink="/bookmakers/bookmakers">Bookmakers</a>
            </li>
            <li
                *hasPermission="'BOOKMAKERS__VIEW_HOLDINGS'"
                nz-menu-item
                [nzMatchRouter]="true"
                routerLink="/bookmakers/holdings"
            >
                Holdings
            </li>
            <li
                *hasPermission="'BOOKMAKERS__VIEW_PAYMENT_METHODS'"
                nz-menu-item
                [nzMatchRouter]="true"
                routerLink="/bookmakers/payment-methods"
            >
                Payment Methods
            </li>
            <li
                *hasPermission="'BOOKMAKERS__VIEW_BONUSES'"
                nz-menu-item
                [nzMatchRouter]="true"
                routerLink="/bookmakers/bonuses"
            >
                Bonuses
            </li>
        </ul>
    </li>
    <li *hasPermission="'WEBSITES__VIEW'" nz-menu-item [nzMatchRouter]="true">
        <span nz-icon nzType="apartment"></span>
        <span><a routerLink="/websites">Websites</a></span>
    </li>
    <li
        *hasPermission="'COMPETITORS__VIEW'"
        nz-menu-item
        [nzMatchRouter]="true"
        routerLink="/competitors"
    >
        <span nz-icon nzType="bug"></span>
        <span>Competitors</span>
    </li>
    <li
        *hasPermission="'DATA_TABLES__VIEW'"
        nz-menu-item
        [nzMatchRouter]="true"
        routerLink="/data-tables"
    >
        <span nz-icon nzType="table" nzTheme="outline"></span>
        <span>Data tables</span>
    </li>
    <li
        *hasPermission="'SERP__VIEW'"
        nz-submenu
        nzIcon="file-done"
        nzTitle="SERP"
        [nzOpen]="this.openMap.serp"
    >
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/serp/analyze">
                Analyze
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/serp/keywords">
                Keywords list
            </li>
        </ul>
    </li>
    <li
        *hasPermission="'TRACKING_MATERIALS__VIEW'"
        nz-menu-item
        [nzMatchRouter]="true"
        routerLink="/tracking-material"
    >
        <span nz-icon nzType="link" nzTheme="outline"></span>
        <span>Tracking Material</span>
    </li>
    <li
        *hasPermission="'SCRAPING__VIEW'"
        nz-submenu
        nzIcon="bar-chart"
        nzTitle="Scraping"
        [nzOpen]="this.openMap.scraping"
    >
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/scraping/dashboard">
                Dashboard
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/scraping/dashboard-old">
                <s>Dashboard (old)</s>
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/scraping/runs">Runs</li>
        </ul>
    </li>
    <li
        *hasPermission="['AFFILIATE_ACCOUNTS__VIEW_ACCOUNTS', 'AFFILIATE_ACCOUNTS__VIEW_PARTNERS']"
        nz-submenu
        nzIcon="contacts"
        nzTitle="Aff. Accounts"
        [nzOpen]="this.openMap.affiliateAccounts"
    >
        <ul>
            <li
                *hasPermission="'AFFILIATE_ACCOUNTS__VIEW_PARTNERS'"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/affiliate-accounts/partners"
            >
                Partners
            </li>
            <li
                *hasPermission="'AFFILIATE_ACCOUNTS__VIEW_ACCOUNTS'"
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/affiliate-accounts/accounts"
            >
                Accounts
            </li>
        </ul>
    </li>
    <li
        *hasPermission="'CTA_TABLE_GROUPS__VIEW'"
        nz-menu-item
        [nzMatchRouter]="true"
        routerLink="/cta-table-groups"
    >
        <span nz-icon nzType="table" nzTheme="outline"></span>
        <span>CTA Table groups</span>
    </li>
    <li
        *hasPermission="['LOCALIZATIONS__VIEW_COUNTRIES', 'LOCALIZATIONS__VIEW_MARKETS']"
        nz-submenu
        nzIcon="compass"
        nzTitle="Localization"
        [nzOpen]="this.openMap.localization"
    >
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/localization/countries">
                Countries
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/localization/markets">
                Markets
            </li>
        </ul>
    </li>
    <li
        *hasPermission="'UPCOMING_MATCHES_BLOCKS__VIEW'"
        nz-menu-item
        [nzMatchRouter]="true"
        routerLink="/upcoming-matches-blocks"
    >
        <span nz-icon nzType="pic-left" nzTheme="outline"></span>
        <span>Upcoming Matches blocks</span>
    </li>
</ul>
