import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, of, switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserResponseDto } from 'src/app/core/services/moveup-api/users/users/users.dtos';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    user: UserResponseDto;
    environmentTag: { name: string; color: string } = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this._setEnvironmentTag();

        this.authService
            .getUser()
            .pipe(
                distinctUntilChanged((a, b) => {
                    return (
                        a?.id === b?.id &&
                        a?.firstName === b?.firstName &&
                        a?.lastName === b?.lastName
                    );
                }),
                switchMap((user) => {
                    this.user = user;

                    return user ? this.authService.getInfo() : of(null);
                }),
            )
            .subscribe((user) => {
                this.user = user;
                this.store.dispatch(AuthActions.updateUser({ user }));
            });
    }

    logout(): void {
        this.authService.logout();
        void this.router.navigateByUrl('/auth/login');
    }

    editMyProfile(): void {
        void this.router.navigateByUrl('/users/users/edit/' + this.user.id);
    }

    get userInitials(): string {
        if (!this.user) {
            return null;
        }

        return this.user.firstName.slice(0, 1) + this.user.lastName.slice(0, 1);
    }

    private _setEnvironmentTag(): void {
        if (environment.production) {
            return;
        }

        const name = environment.environmentName;
        if (!name) {
            return;
        }

        if (name === 'local') {
            this.environmentTag = { name: 'LOCAL', color: '#3498db' };
        }

        if (name === 'staging') {
            this.environmentTag = { name: 'STAGING', color: '#1abc9c' };
        }

        if (name === 'preprod') {
            this.environmentTag = { name: 'PREPROD', color: '#f39c12' };
        }
    }
}
