import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditCtaTableGroupDto,
    CtaTableGroupBatchRemoveBookmakersDto,
    CtaTableGroupBatchRemoveBookmakersResponseDto,
    CtaTableGroupFiltersDto,
    CtaTableGroupResponseDto,
} from 'src/app/core/services/moveup-api/cta-table-groups/cta-table-groups.dtos';
import { Pagination } from 'src/app/shared/dtos/pagination.dtos';
import { dtoToHttpParams } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export class CtaTableGroupsService {
    baseUrl = `${environment.api.baseUrl}/admin/cta-table-group`;

    constructor(private http: HttpClient) {}

    getList(
        page: number,
        limit: number,
        dto: CtaTableGroupFiltersDto,
    ): Observable<Pagination<CtaTableGroupResponseDto>> {
        return this.http.get<Pagination<CtaTableGroupResponseDto>>(
            `${this.baseUrl}/list/${page}/${limit}`,
            { params: dtoToHttpParams(dto) },
        );
    }

    getCtaTableGroup(id: string): Observable<CtaTableGroupResponseDto> {
        return this.http.get<CtaTableGroupResponseDto>(`${this.baseUrl}/${id}`);
    }

    add(dto: AddEditCtaTableGroupDto): Observable<CtaTableGroupResponseDto> {
        return this.http.post<CtaTableGroupResponseDto>(`${this.baseUrl}/add`, dto);
    }

    edit(id: string, dto: AddEditCtaTableGroupDto): Observable<CtaTableGroupResponseDto> {
        return this.http.put<CtaTableGroupResponseDto>(`${this.baseUrl}/edit/${id}`, dto);
    }

    batchRemoveBookmakersPreview(
        dto: CtaTableGroupBatchRemoveBookmakersDto,
    ): Observable<CtaTableGroupBatchRemoveBookmakersResponseDto> {
        return this.http.post<CtaTableGroupBatchRemoveBookmakersResponseDto>(
            `${this.baseUrl}/batch-remove-bookmakers-preview`,
            dto,
        );
    }

    batchRemoveBookmakers(
        dto: CtaTableGroupBatchRemoveBookmakersDto,
    ): Observable<CtaTableGroupBatchRemoveBookmakersResponseDto> {
        return this.http.post<CtaTableGroupBatchRemoveBookmakersResponseDto>(
            `${this.baseUrl}/batch-remove-bookmakers`,
            dto,
        );
    }

    remove(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/remove/${id}`);
    }
}
